import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import Slices from '../components/slices'
import NewsSidebar from '../components/NewsSidebar'

// Query for the Blog Post content in Prismic
export const query = graphql`
query Post {
  allPrismicPost {
    edges {
      node {
        id
        uid
        type
        data {
          date
          title {
            html
            text
            raw
          }
          body {
            __typename
            ... on PrismicPostBodyText {
              slice_type
              slice_label
              primary {
                text {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPostBodyQuote {
              slice_type
              slice_label
              primary {
                quote {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPostBodyImageWithCaption {
              slice_type
              slice_label
              primary {
                image {
                  alt
                  copyright
                  url
                }
                caption {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPostBodyImageGallery {
              slice_type
              slice_label
              items {
                gallery_image {
                  alt
                  copyright
                  url
                }
                image_captions {
                  html
                  text
                  raw
                }
              }
              primary {
                name_of_the_gallery {
                  text
                  raw
                  html
                }
              }
            }
          }
        }
      }
    }
  }
}
`

// Display the title, date, and content of the Post
const PostBody = ({ blogPost }) => {
  return (
    <div>
      {/* Render the edit button */}
      <h1 data-wio-id={ blogPost.id }>
        { blogPost.data.title.text }
      </h1>
      {/* Go through the slices of the post and render the appropiate one */}
      <Slices slices={ blogPost.data.body } />
    </div>
  );
}

export default (props) => {
  // Define the Post content returned from Prismic
  const doc = props.data.allPrismicPost.edges.filter(post => post.node.uid === props.pageContext.uid)[0];
  const posts = props.data.allPrismicPost.edges;

  if(!doc) return null;

  return(
    <Layout title={ doc.node.data.title.text }>
      <div class="pure-g">
        <NewsSidebar posts={posts} />
        <div class="pure-u-lg-3-5 pure-u-1-1 main">
          <PostBody blogPost={ doc.node } />
        </div>
      </div>
    </Layout>
  )
}
