import React from 'react'
import ImageCaption from './ImageCaption'
import ImageGallery from './ImageGallery'
import Quote from './Quote'
import Text from './Text'

export default (props) => {
  const { slices } = props;
  return slices.map((slice, index) => {
    const res = (() => {
      switch(slice.slice_type) {
        case 'text': return <Text slice={slice} />;
        case 'quote': return <Quote slice={ slice } />
        case 'image_with_caption': return <ImageCaption slice={ slice } />
        case 'image_gallery': return <ImageGallery slice={ slice } />
        default: return
      }
    })();
    return <div key={ index }>{res}</div>;
  });
}
