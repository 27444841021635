import React from 'react'
import { Link } from "gatsby"
import { Date } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

export default ({ posts }) => {
  const monthlyPosts = posts.reduce((iter, post) => {
    let postDate = Date(post.node.data.date);
    postDate = postDate ? 
      new Intl.DateTimeFormat('en-US', {
        month: 'long',
        year: 'numeric'
      }).format(postDate) :
      '';
    iter[postDate] = iter[postDate] || [];
    iter[postDate].push(post);
    return iter
  }, {})

  return (
    <div class="pure-u-lg-1-5 pure-u-1-1 sidebar">
      <ul>
        {Object.keys(monthlyPosts).map(month => (
          <>
            <li className="month">{month}</li>
            {monthlyPosts[month].map(post => (
              <li>
                <Link to={ linkResolver(post.node) }>
                  { post.node.data.title.text }
                </Link>
              </li>
            ))}
          </>
        ))}
      </ul>
    </div>
  )
}