import React from 'react'

export default ({ slice }) => {
  switch(slice.__typename) {
    case "PrismicPolicyBodyText":
      return slice.items.map(item => (
        <div className="slice-text">
          <h4>{ item.heading.text }</h4>
          <div dangerouslySetInnerHTML={{__html: item.content.html}} />
        </div>
      ))
    default:
      return (
        <div className="slice-text">
          <div dangerouslySetInnerHTML={{__html: slice.primary.text.html}} />
        </div>
      )
  }
}