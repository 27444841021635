import React from 'react'
import Carousel from '../Carousel';

export default ({ slice }) => {
  return (
    <Carousel
      images={slice.items.map(i => i.gallery_image)}
      title={slice.primary.name_of_the_gallery.text}
      controls={true}
      auto={false}
    />
  )
}
