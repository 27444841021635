import React, { Fragment } from 'react'

// Default Image
const DefaultImage = ({ slice }) =>
  <div className="slice-image">
    <figcaption className="block-img">
      <img src={ slice.primary.image.url } alt={ slice.primary.image.alt } />
      { slice.primary.caption?.text !== ""
        ? <figcaption className="image-label">
          { slice.primary.caption.text }
          </figcaption>
        : null
      }
    </figcaption>
  </div>

// Emphasized Image
const EmphasizedImage = ({ slice }) =>
  <div className="slice-image">
    <figcaption className="block-img emphasized">
      <img src={ slice.primary.image.url } alt={ slice.primary.image.alt } />
      { slice.primary.caption?.text !== ""
        ? <figcaption className="image-label">
          { slice.primary.caption.text }
          </figcaption>
        : null
      }
    </figcaption>
  </div>

// Full Width Image
const FullWidthImage = ({ slice }) =>
  <div className="slice-image full-width-image" style={{ backgroundImage: 'url('+ slice.primary.image.url +')' }}>
    <div className="wrapper">
      { slice.primary.caption?.text !== ""
        ? <span className="image-label">{ slice.primary.caption.text }</span>
        : null
      }
    </div>
  </div>

// Function to determine the image type
const renderSwitch = function(slice) {
  switch(slice.slice_label) {
    case "image-full-width":
      return <FullWidthImage slice={ slice } />
    case "emphasized":
      return <EmphasizedImage slice={ slice } />
    default:
      return <DefaultImage slice={ slice } />
  }
}

export default ({ slice }) => {
  return (
    <Fragment>
      { renderSwitch(slice) }
    </Fragment>
  );
}
